import { SvgIcon } from '@mui/material'

const ClockCardAtom = () => {
	return (
		<SvgIcon viewBox="0 0 19 20" width={19} height={20}>
			<path
				fill="currentColor"
				d="M9.92814 0.783203C7.54528 0.783203 5.25978 1.76277 3.574 3.50596C1.88922 5.25007 0.942627 7.61516 0.942627 10.0808C0.942627 12.5464 1.88932 14.9113 3.574 16.6556C5.25957 18.3989 7.54528 19.3783 9.92814 19.3783C12.311 19.3783 14.5965 18.3988 16.2823 16.6556C17.9671 14.9115 18.9137 12.5464 18.9137 10.0808C18.9112 7.61601 17.9638 5.25262 16.279 3.50936C14.5942 1.76609 12.3102 0.785753 9.92814 0.783203ZM10.57 9.80854C10.57 10.1605 10.4336 10.4976 10.1913 10.7449L7.17461 13.8729C6.92188 14.1295 6.51594 14.1295 6.26322 13.8729C6.0129 13.6123 6.0129 13.1906 6.26322 12.9299L9.28625 9.80847V4.1037C9.28625 3.73677 9.57346 3.43959 9.92808 3.43959C10.2827 3.43959 10.5699 3.73677 10.5699 4.1037L10.57 9.80854Z"
			/>
		</SvgIcon>
	)
}

export default ClockCardAtom
